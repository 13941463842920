<script setup lang="ts">
import Close from 'assets/icons/close.svg'

const { data: staticKeys } = await useFetchMessages()

const props = defineProps({
    isVisible: Boolean,
    closeButton: {
        type: Boolean,
        default: true,
    },
    overlayClose: {
        type: Boolean,
        default: true,
    },
})

const emit = defineEmits(['close'])

const closeModal = () => {
    emit('close')
}

const closeOnOverlayClick = () => {
    if (props.overlayClose) {
        closeModal()
    }
}
</script>

<template>
    <div v-if="isVisible" class="modal-overlay" data-color-scheme="light" @click="closeOnOverlayClick">
        <div class="modal-container" @click.stop>
            <div
                v-if="closeButton" class="modal-close" :title="staticKeys?.close" :aria-label="staticKeys?.close"
                @click="closeModal"
            >
                <Close />
            </div>
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
.modal-overlay {
    position: fixed;
    inset: 0;
    z-index: z('modal');
    background: $modal-overlay-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
}

.modal-container {
    background: $modal-container-bg;
    border-radius: 20px;
    max-width: 90%;
    width: 500px;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 15px;
        height: 15px;
    }
}
</style>
