<script setup lang="ts">
import Share from 'assets/icons/share.svg'
import Star from 'assets/icons/star.svg'
import { useModal } from '~/composables/useModal'

const { isVisible, closeModal, openModal } = useModal()

const { data: staticKeys } = await useFetchMessages()

const introButtonConfig = computed(() => {
    return {
        type: 'internal',
        buttonType: 'secondary',
        active: true,
        center: true,
        large: true,
        link: '/formular'
    }
})

const shareButtonConfig = computed(() => {
    return {
        type: 'div',
        buttonType: 'primary',
        active: true,
        center: true,
        large: true,
    }
})

// Check if the browser is running in standalone mode
const isStandalone = computed(() => {
    return window?.matchMedia('(display-mode: standalone)').matches || window?.navigator?.standalone
})

const device = useDevice()

const showShareButton = computed(() => {
    return (device?.isIos || device?.isAndroid) && !isStandalone.value
})

onMounted(() => {
    if (import.meta.client) {
        window.AddToHomeScreenInstance = new window.AddToHomeScreen(
            {
                appName: staticKeys.value?.appName, // Name of the app. [Required]
                appIconUrl: '/apple-touch-icon.png', // App icon link (square, at least 40 x 40 pixels) [Required]
                assetUrl: '/share/', // Link to directory of library image assets [Required]
                maxModalDisplayCount: -1 // If set, the modal will only show this many times [Optional. Default: -1 (no limit).]
                // (Debugging: Use this.clearModalDisplayCount() to reset the count)
            }
        )
    }
})

const handleShare = () => {
    if (showShareButton.value) {
        setTimeout(() => {
            window?.AddToHomeScreenInstance.show('de')
        }, 100)
    } else {
        openModal()
    }
}
</script>

<template>
    <section class="home-intro">
        <Subline :subline-config="{ title: staticKeys?.home?.intro.subline }" />
        <MainHeadline :main-headline-config="{ title: staticKeys?.home?.intro.headline }" />
        <p v-html="staticKeys?.home?.intro?.copy" />

        <div class="home-intro-button-bar">
            <BaseButton :base-button-config="introButtonConfig">
                {{ staticKeys?.home?.intro.cta }}
            </BaseButton>

            <BaseButton v-if="! isStandalone" class="share-btn" :base-button-config="shareButtonConfig" @click="handleShare">
                <Share />
                {{ staticKeys?.home?.intro.share }}
            </BaseButton>
        </div>
    </section>

    <Transition name="fade" mode="out-in">
        <Modal :is-visible="isVisible" @close="closeModal">
            <img src="/apple-touch-icon.png" alt="Apple Touch Icon" class="share-image">
            <div class="share-modal-content">
                <h2>{{ staticKeys?.home?.intro.modal.headline }}</h2>
                <p>
                    <span v-html="staticKeys?.home?.intro.modal.copy1" />
                    <Star />
                </p>
                <p v-html="staticKeys?.home?.intro.modal.copy2" />
                <p>
                    {{ staticKeys?.home?.intro.modal.copy3 }}
                    <Share />
                </p>
            </div>
        </Modal>
    </Transition>
</template>

<style lang="scss">
.home-intro {
    text-align: center;
    grid-column: 2 / -2;
    @include section-padding($first: true);

    p {
        @include copytext;
    }
}

.home-intro-button-bar {
    align-items: center;
    justify-content: center;

    @include bp(medium1) {
        display: flex;
    }
}

.share-modal-content {
    text-align: center;
    padding: 60px 60px 20px;

    h2 {
        font-family: $font-headline;
        padding: 0;
        margin: 0 0 px2rem(20);
        font-size: px2rem(18);
    }

    p {
        margin: 0 0 px2rem(30);
        padding: 0;
        font-size: px2rem(16);

        &:last-child {
            margin: 0;
        }

        svg {
            width: 20px;
            fill: currentcolor;
            margin: 10px auto;
            display: block;
        }
    }
}

.share-image {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 80px;

}

.share-btn {
    margin-block-start: px2rem(20);

    @include bp(medium1) {
        margin: 0 0 0 px2rem(20);
    }

    svg {
        width: 25px;
        fill: currentcolor;
        margin-inline-end: 20px;
    }

    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

.adhs-container {
    display: block;
    position: fixed;
    min-width: 100vw;
    max-width: calc(100vw - 40px);
    min-height: 100vh;
    overflow: auto;
    inset: 0;
    background-color: $adhs-container-bg;
    z-index: 9999;
    opacity: 0;
    color: $body-text-light;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
    backdrop-filter: blur(5px);

    &.visible {
        background-color: $adhs-container-visible-bg;
        opacity: 1;
    }
}

.adhs-modal {
    background-color: $adhs-modal-bg;
    border-radius: 20px;
    box-shadow: 0 2px 10px $adhs-modal-box-shadow;
    padding: 49px 19px 32px;
    margin: px2rem(60) auto auto;
    width: 90%;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    line-height: normal;
}

.adhs-modal,
.adhs-logo {
    .visible & {
        transform: translateY(0);
        opacity: 1;
    }

}

.adhs-logo {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    margin: auto;
    height: 80px;
    width: 80px;
    z-index: 10000;

    img {
        width: 80px;
        height: 80px;
        border-radius: 12px;
    }
}

.adhs-error-title,
.adhs-title {
    font-size: px2rem(24);
    font-family: $font-headline;
    padding: 0 0 18px;
    text-align: center;
}

.adhs-container .adhs-modal .adhs-error-body {
    font-size: px2rem(14);
    margin: 0 10px 20px;
    text-align: justify;
}

.adhs-container .adhs-modal .adhs-error-copy-link-button {
    background-color: white;
    border: 1px solid;
    border-radius: 4px;
    font-size: px2rem(14);
    padding: 5px;
}

.adhs-container .adhs-modal .adhs-list {
    display: flex;
    flex-direction: column;
}

.adhs-container .adhs-modal .adhs-list .adhs-emphasis {
    font-family: $font-headline;
}

.adhs-container .adhs-modal .adhs-list .adhs-your-app-icon {
    height: 40px;
    margin: 0 5px -13px;
    border-radius: 6px;
}

.adhs-container .adhs-modal .adhs-list .adhs-list-item {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
    margin: 0 0 10px;
}

.adhs-container .adhs-modal .adhs-list .adhs-list-item .adhs-number-container {
    flex-direction: column;
    display: flex;
    margin: 0 5px 0 0;
}

.adhs-container .adhs-modal .adhs-list .adhs-list-item .adhs-circle {
    height: 25px;
    width: 25px;
    border-radius: 100%;
    background-color: $secondary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-start: 10px;
}

.adhs-container .adhs-modal .adhs-list .adhs-list-item .adhs-number {
    font-size: px2rem(14);
    color: $body-text;
    line-height: 1;
}

.adhs-container .adhs-modal .adhs-list .adhs-list-item .adhs-instruction {
    font-size: px2rem(14);
    line-height: 1.7;
    padding: 0 5px;
}

/* IOS Safari */
.adhs-container.adhs-ios.adhs-safari .adhs-modal .adhs-list .adhs-ios-safari-sharing-api-button {
    height: 35px;
    margin: 0 5px -7px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
    background-color: #fff;
}

.adhs-container.adhs-ios.adhs-safari .adhs-modal .adhs-list .adhs-ios-safari-add-to-home-screen-button {
    height: 33px;
    margin: 0 5px -7px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
    background-color: #fff;
}

.adhs-container.adhs-ios.adhs-safari .adhs-ios-safari-bouncing-arrow-container img {
    height: 60px;
    position: absolute;
    top: calc(100dvh - 60px);
    left: 0;
    right: 0;
    margin: auto;
    animation: adhs-ios-safari-bouncing-arrow-animation 2s infinite;
}

.adhs-container.adhs-ios.adhs-chrome .adhs-modal .adhs-list .adhs-ios-chrome-more-button {
    height: 35px;
    margin: 0 5px -7px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
    background-color: #fff;
}

.adhs-container.adhs-ios.adhs-chrome .adhs-ios-chrome-bouncing-arrow-container img {
    height: 40px;
    position: absolute;
    top: 0;
    right: 18px;
    animation: adhs-ios-chrome-bouncing-arrow-animation 1.5s infinite;
}

.adhs-container.adhs-ios.adhs-chrome .adhs-modal .adhs-list .adhs-ios-chrome-add-to-home-screen-button {
    height: 35px;
    margin: 0 5px -7px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
    background-color: #fff;
}

.adhs-container.adhs-android.adhs-chrome .adhs-android-chrome-bouncing-arrow-container img {
    height: 40px;
    position: absolute;
    top: 0;
    right: 15px;
    animation: adhs-android-chrome-bouncing-arrow-animation 1.5s infinite;
}

.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-android-chrome-more-button {
    height: 30px;
    background: white;
    margin: 0 5px -7px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
}

.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-android-chrome-add-to-homescreen-button {
    height: 30px;
    background: white;
    margin: 0 5px -9px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
    padding: 0 7px 0 4px;
}

.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-android-chrome-install-app {
    height: 30px;
    background: white;
    margin: 10px 5px -10px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
    padding: 0;
}

.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-list-item .adhs-instruction {
    line-height: 34px;
}

/* In-App System Browser */
.adhs-container.adhs-inappbrowser-openinsystembrowser .adhs-modal .adhs-title {
    padding: 0 0 9px;
}

.adhs-container.adhs-inappbrowser-openinsystembrowser .adhs-modal .adhs-list .adhs-emphasis {
    padding: 6px 8px;
    border-radius: 5px;
    margin: 0 0 0 5px;
    box-shadow: 1px 1px 2px gray;
    background: white;
    width: fit-content;
}

.adhs-container.adhs-inappbrowser-openinsystembrowser .adhs-modal .adhs-list .adhs-list-item {
    margin: 6px 0 9px;
}

.adhs-container.adhs-inappbrowser-openinsystembrowser .adhs-modal .adhs-list .adhs-list-item .adhs-instruction {
    padding: 0 0 2px 9px;
    line-height: 42px;
}

.adhs-container.adhs-inappbrowser-openinsystembrowser .adhs-inappbrowser-openinsystembrowser-bouncing-arrow-container img {
    height: 40px;
    position: absolute;
    top: 0;
    right: 6px;
    animation: adhs-inappbrowser-openinsystembrowser-bouncing-arrow-animation 1.5s infinite;
}

.adhs-container.adhs-inappbrowser-openinsystembrowser .adhs-modal .adhs-list .adhs-more-button {
    height: 35px;
    background: white;
    margin: 0 5px -11px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
}

/* In-App Safari Browser */
.adhs-container.adhs-inappbrowser-openinsafari .adhs-modal .adhs-title {
    padding: 0 0 9px;
}

.adhs-container.adhs-inappbrowser-openinsafari .adhs-modal .adhs-list .adhs-emphasis {
    padding: 6px 8px;
    border-radius: 5px;
    margin: 0 0 0 5px;
    box-shadow: 1px 1px 2px gray;
    background: white;
    width: fit-content;
    font-weight: 500;
}

.adhs-container.adhs-inappbrowser-openinsafari .adhs-modal .adhs-list .adhs-list-item {
    margin: 6px 0 9px;
}

.adhs-container.adhs-inappbrowser-openinsafari .adhs-modal .adhs-list .adhs-list-item .adhs-instruction {
    padding: 0 0 0 9px;
    line-height: 34px;
    margin: 4px 0 0;
}

.adhs-container.adhs-inappbrowser-openinsafari .adhs-inappbrowser-openinsafari-bouncing-arrow-container img {
    height: 40px;
    position: absolute;
    top: calc(100dvh - 40px);
    right: 15px;
    animation: adhs-inappbrowser-openinsafari-bouncing-arrow-animation 1.5s infinite;
}

.adhs-container.adhs-inappbrowser-openinsafari .adhs-modal .adhs-list .adhs-more-button {
    height: 35px;
    background: white;
    margin: 0 5px -11px;
    box-shadow: 1px 1px 2px gray;
    border-radius: 4px;
}

@keyframes adhs-android-chrome-bouncing-arrow-animation {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(20px);
    }

    60% {
        transform: translateY(5px);
    }
}

@keyframes adhs-inappbrowser-openinsystembrowser-bouncing-arrow-animation {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(20px);
    }

    60% {
        transform: translateY(5px);
    }
}

@keyframes adhs-inappbrowser-openinsafari-bouncing-arrow-animation {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-5px);
    }
}

@keyframes adhs-ios-safari-bouncing-arrow-animation {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

@keyframes adhs-ios-chrome-bouncing-arrow-animation {
    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(20px);
    }

    60% {
        transform: translateY(5px);
    }
}
</style>
