<script setup lang="ts">
import Facebook from 'assets/logos/facebook-logo.svg'
import Check from 'assets/icons/checked.svg'
import Go from 'assets/icons/go.svg'

const { data: staticKeys } = await useFetchMessages()

const introButtonConfig = computed(() => {
    return {
        type: 'internal',
        buttonType: 'secondary',
        active: true,
        large: true,
        center: true,
        link: '/formular'
    }
})
</script>

<template>
    <section class="home-infos-wrapper">
        <div class="home-infos-container">
            <ContentHeadline :content-headline-config="{ title: staticKeys?.home?.infos?.headline }" />
            <p v-html="staticKeys?.home?.infos?.copy1" />

            <ul class="home-infos-list">
                <li class="home-infos-list-item">
                    <picture>
                        <RespImgSrc
                            tag-type="source"
                            image-src="/missing-children/missing-children-form.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            :format="['webp', 'avif', 'jpg']"
                            :width="244"
                            :height="345"
                            sizes="(min-width: 1440px) 244px, (min-width: 1024px) calc((92vw - 240px) / 4), (min-width: 768px) calc((92vw - 120px) / 4), 244px"
                        />
                        <RespImgSrc
                            tag-type="img"
                            image-src="/missing-children/missing-children-form.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            sizes="244px"
                            :format="['jpg']"
                            :width="244"
                            :height="345"
                            :additional-attrs="{ loading: 'lazy', alt: staticKeys?.home?.infos?.reach.altText1 }"
                        />
                    </picture>
                    <Go class="go-icon" />
                </li>
                <li class="home-infos-list-item">
                    <h3><Facebook /></h3>
                    <picture>
                        <RespImgSrc
                            tag-type="source"
                            image-src="/missing-children/missing-children-facebook.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            :format="['webp', 'avif', 'jpg']"
                            :width="244"
                            :height="482"
                            sizes="(min-width: 1440px) 244px, (min-width: 1024px) calc((92vw - 240px) / 4), (min-width: 768px) calc((92vw - 120px) / 4), 244px"
                        />
                        <RespImgSrc
                            tag-type="img"
                            image-src="/missing-children/missing-children-facebook.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            sizes="244px"
                            :format="['jpg']"
                            :width="244"
                            :height="482"
                            :additional-attrs="{ loading: 'lazy', alt: staticKeys?.home?.infos?.reach.altText2 }"
                        />
                    </picture>
                    <Check class="checked-icon" />
                </li>
                <li class="home-infos-list-item">
                    <h3>{{ staticKeys?.home?.infos?.reach.headline3 }}</h3>
                    <picture>
                        <RespImgSrc
                            tag-type="source"
                            image-src="/missing-children/missing-children-instagram.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            :format="['webp', 'avif', 'jpg']"
                            :width="244"
                            :height="322"
                            sizes="(min-width: 1440px) 244px, (min-width: 1024px) calc((92vw - 240px) / 4), (min-width: 768px) calc((92vw - 120px) / 4), 244px"
                        />
                        <RespImgSrc
                            tag-type="img"
                            image-src="/missing-children/missing-children-instagram.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            sizes="244px"
                            :format="['jpg']"
                            :width="244"
                            :height="322"
                            :additional-attrs="{ loading: 'lazy', alt: staticKeys?.home?.infos?.reach.altText3 }"
                        />
                    </picture>
                    <Check class="checked-icon" />
                </li>
                <li class="home-infos-list-item">
                    <h3>{{ staticKeys?.home?.infos?.reach.headline4 }}</h3>
                    <picture>
                        <RespImgSrc
                            tag-type="source"
                            image-src="/missing-children/missing-children-car.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            :format="['webp', 'avif', 'jpg']"
                            :width="244"
                            :height="130"
                            sizes="(min-width: 1440px) 244px, (min-width: 1024px) calc((92vw - 240px) / 4), (min-width: 768px) calc((92vw - 120px) / 4), 244px"
                        />
                        <RespImgSrc
                            tag-type="img"
                            image-src="/missing-children/missing-children-car.png"
                            :srcset="[244]"
                            :dpr="[1]"
                            sizes="244px"
                            :format="['jpg']"
                            :width="244"
                            :height="130"
                            :additional-attrs="{ loading: 'lazy', alt: staticKeys?.home?.infos?.reach.altText4 }"
                        />
                    </picture>

                    <Check class="checked-icon" />
                </li>
            </ul>

            <p v-html="staticKeys?.home?.infos?.copy2" />

            <BaseButton :base-button-config="introButtonConfig">
                {{ staticKeys?.home?.infos?.cta }}
            </BaseButton>
        </div>
    </section>
</template>

<style lang="scss">
.home-infos-wrapper {
    overflow: hidden;
    position: relative;
    grid-column: 1 / -1;
    text-align: center;

    @include fullbleed;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 50%;
        pointer-events: none;
        background: $home-infos-gradient;
        z-index: 0;
    }
}

.home-infos-container {
    grid-column: 2 / -2;
    position: relative;
    z-index: 1;

    @include section-padding;

    p {
        @include copytext;

        &:last-of-type {
            max-width: 600px;
        }
    }
}

.home-infos-list {
    display: grid;
    grid-template-columns: 1fr;
    list-style-type: none;
    margin-block: px2rem(40) px2rem(20);
    align-items: center;
    padding: 0;

    @include bp(medium1) {
        grid-template-columns: repeat(4, 1fr);
        margin-block: px2rem(60) px2rem(100);
        gap: px2rem(40);
    }

    @include bp(large1) {
        gap: px2rem(80);
    }
}

.home-infos-list-item {
    position: relative;
    max-width: 300px;
    margin: 0 auto px2rem(40);

    &:first-child {
        margin-block-end: px2rem(100);

        @include bp(medium1) {
            margin: 0;
        }
    }

    @include bp(medium1) {
        max-width: 100%;
        margin: 0;
    }

    h3 {
        font-size: px2rem(14);
        margin-block-end: px2rem(20);
        font-weight: normal;

        svg {
            height: 20px;
        }
    }

    img {
        max-width: 100%;
        height: auto;
        width: auto;
        display: block;
    }
}

.checked-icon {
    width: 30px;
    height: 30px;
    fill: currentcolor;
    margin-block-start: px2rem(10);
}

.go-icon {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-100%) rotate(90deg);

    @include bp(medium1) {
        inset: 50% -50px auto auto;
        transform: translateX(-100%) translateY(0) rotate(0deg);
    }

    @include bp(large1) {
        right: -70px;
    }
}
</style>
