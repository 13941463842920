<script setup lang="ts">
const { data: staticKeys } = await useFetchMessages()
</script>

<template>
    <section class="home-instructions-wrapper" data-color-scheme="light">
        <div class="home-instructions-container">
            <aside class="home-instructions-pattern">
                <PagePatternImage />
            </aside>

            <ContentHeadline :content-headline-config="{ title: staticKeys?.home?.instructions?.headline }" />

            <div class="home-instructions-content-container">
                <div class="home-instructions-content home-instructions-content-formular">
                    <picture>
                        <RespImgSrc
                            tag-type="source"
                            image-src="/missing-children/missing-children-form.png"
                            :srcset="[300]"
                            :dpr="[1]"
                            :format="['webp', 'avif', 'jpg']"
                            :width="300"
                            :height="345"
                            sizes="(min-width: 1440px) 300px; min-width: 1024px) 200px; 150px"
                        />
                        <RespImgSrc
                            tag-type="img"
                            image-src="/missing-children/missing-children-form.png"
                            :srcset="[300]"
                            :dpr="[1]"
                            sizes="150px"
                            :format="['jpg']"
                            :width="300"
                            :height="345"
                            :additional-attrs="{ loading: 'lazy', alt: staticKeys?.home?.instructions?.subline1 }"
                        />
                    </picture>
                    <div class="home-instructions-text">
                        <Subline :subline-config="{ title: staticKeys?.home?.instructions?.subline1 }" />
                        <p v-html="staticKeys?.home?.instructions?.copy1" />
                    </div>
                </div>

                <div class="home-instructions-content home-instructions-content-facebook">
                    <div class="home-instructions-text">
                        <Subline :subline-config="{ title: staticKeys?.home?.instructions?.subline2 }" />
                        <p v-html="staticKeys?.home?.instructions?.copy2" />
                    </div>
                    <picture>
                        <RespImgSrc
                            tag-type="source"
                            image-src="/missing-children/missing-children-facebook.png"
                            :srcset="[300]"
                            :dpr="[1]"
                            :format="['webp', 'avif', 'jpg']"
                            :width="300"
                            :height="593"
                            sizes="(min-width: 1440px) 300px; min-width: 1024px) 200px; 150px"
                        />
                        <RespImgSrc
                            tag-type="img"
                            image-src="/missing-children/missing-children-facebook.png"
                            :srcset="[300]"
                            :dpr="[1]"
                            sizes="150px"
                            :format="['jpg']"
                            :width="300"
                            :height="593"
                            :additional-attrs="{ loading: 'lazy', alt: staticKeys?.home?.instructions?.subline2 }"
                        />
                    </picture>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
.home-instructions-wrapper {
    grid-column: 1 / -1;
    text-align: center;
    overflow: hidden;
    position: relative;
    background: $home-instructions-bg;
    @include fullbleed;
}

.home-instructions-container {
    grid-column: 2 / -2;
    @include section-padding;
}

.home-instructions-pattern {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    opacity: 0.4;
    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: $home-instructions-gradient;
        z-index: 1;
    }
}

.home-instructions-content-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: $page-grid-gap-mobile;
    position: relative;
    z-index: 1;

    @include bp(medium1) {
        grid-template-columns: 1fr 1fr;
        gap: px2rem(60);
    }
}

.home-instructions-content {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: $page-grid-gap-mobile;

    @include bp(medium1) {
        display: block;
    }

    &.home-instructions-content-formular  {
        text-align: left;
    }

    &.home-instructions-content-facebook {
        text-align: right;

        @include bp(medium1) {
            padding-block-start: px2rem(100);
        }
    }

    img {
        width: 150px;
        height: auto;

        @include bp(large1) {
            width: 200px;
        }

        @include bp(large2) {
            width: 300px;
        }
    }
}

.home-instructions-text {
    @include bp(small2) {
        padding-block-start: px2rem(20);
    }

    @include bp(medium1) {
        position: absolute;
        width: 100%;
        max-width: 400px;
    }

    .home-instructions-content-formular & {
        text-align: left;
        left: 200px;
        top: 0;

        @include bp(large1) {
            width: 280px;
            left: 260px;
        }

        @include bp(large2) {
            left: 380px;
            width: 380px;
            top: px2rem(40);
        }
    }

    .home-instructions-content-facebook & {
        text-align: right;

        @include bp(medium1) {
            right: 200px;
            bottom: 0;
        }

        @include bp(large1) {
            right: 260px;
            width: 280px;
        }

        @include bp(large2) {
            right: 380px;
            width: 380px;
            bottom: px2rem(140);
        }
    }

    p {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-100%);
            height: 2px;
            background: black;
            width: 20px;
            display: none;

            @include bp(medium1) {
                display: block;
            }

            @include bp(large2) {
                width: 40px;
            }

            .home-instructions-content-formular & {
                left: -35px;

                @include bp(large1) {
                    left: -40px;
                }

                @include bp(large2) {
                    left: -60px;
                }
            }

            .home-instructions-content-facebook & {
                right: -35px;

                @include bp(large1) {
                    right: -40px;
                }

                @include bp(large2) {
                    right: -60px;
                }
            }
        }
    }
}
</style>
