<script setup lang="ts">
const { data: staticKeys } = await useFetchMessages()

// Seo
useSeoMeta({
    titleTemplate: `%s - ${staticKeys.value?.title}`,
    title: staticKeys.value?.home.title
})
</script>

<template>
    <PageMainContainer>
        <HomeIntro />
        <HomeInstructions />
        <HomeInfos />
    </PageMainContainer>
</template>
